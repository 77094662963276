import Vue from 'vue';
import Router from 'vue-router';
import util from './libs/util.js'
import { Tabbar, TabbarItem } from 'vant';
Vue.use(Tabbar);
Vue.use(TabbarItem);
Vue.use(Router);


const routes = [{
        path: '*',
        redirect: '/index'
    },
    {
        name: 'user',
        component: () => import('./view/user'),
        meta: {
            title: '我的',
            hasBottom: true,
        }
    },
    {
        name: 'cart',
        path:'/cart/:id',
        component: () => import('./view/cart'),
        meta: {
            title: '预约详情',
            // hasBottom: true,
            // auth: true
        }
    },
    {
        name: 'data',
        component: () => import('./view/data'),
        meta: {
            title: '今日预约',
            hasBottom: true,
        }
    },
    {
        name: 'index',
        component: () => import('./view/index'),
        meta: {
            title: '今日预约',
            hasBottom: true,
            // auth: true
        }
    },

    {
        name: 'login',
        component: () => import('./view/login'),
        meta: {
            title: '授权登陆'
        }
    }
];

// add route path
routes.forEach(route => {
    route.path = route.path || '/' + (route.name || '');
});

const router = new Router({ routes });

router.beforeEach((to, from, next) => {
    console.log('here')
    const token = util.cookies.get('token')
    console.log(token)
    if (token && token !== 'undefined' && to.path === '/login') {
        
        // next({name: "index"})
        router.push('/index');
    }

    if (to.path === '/login') {
        next()
    }

    // 验证当前路由所有的匹配中是否需要有登录验证的
    if (to.matched.some(r => r.meta.auth)) {
        // 这里暂时将cookie里是否存有token作为验证是否登录的条件
        // 请根据自身业务需要修改
        console.log('login')
        if (token && token !== 'undefined') {
            next();

        } else {
            // 没有登录的时候跳转到登录界面
            // 携带上登陆成功之后需要跳转的页面完整路径

            // router.push('/login');
            next({
                name: 'login',
                query: {
                    redirect: to.fullPath
                }
            })
        }
    } else {
        // 不需要身份校验 直接通过
        next()
    }
    

});


router.afterEach(to => {
    
    const title = to.meta && to.meta.title;
    if (title) {
        document.title = title;
    }
})

export {
    router
};