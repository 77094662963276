<template>
    <div id="app">
        <router-view />
        <van-tabbar route  v-if="$route.meta.hasBottom">
            <van-tabbar-item replace to="/index" icon="home-o">预约</van-tabbar-item>
            <!-- <van-tabbar-item replace to="/data" icon="notes-o">预约</van-tabbar-item> -->
            <van-tabbar-item replace to="/user" icon="user-o">我的</van-tabbar-item>
        </van-tabbar>
    </div>
</template>
<script>
export default {
    data() {
        return {
            active: 0
        };
    },
}
</script>
<style>
body {
    font-size: 16px;
    background-color: #f8f8f8;
    -webkit-font-smoothing: antialiased;
}
</style>